import React from 'react'
import CrmQuotationsent from "../components/DashboardCrm/CrmQuotationSent/index"

function CrmQuotationSent() {
  return (
    <div>
      <CrmQuotationsent></CrmQuotationsent>
    </div>
  )
}

export default CrmQuotationSent
