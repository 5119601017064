import React from 'react'
import Crmqualified from '../components/DashboardCrm/CrmQualified'
function CrmQualified() {
  return (
    <div>
      <Crmqualified></Crmqualified>
    </div>
  )
}

export default CrmQualified
