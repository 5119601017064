import React from 'react'
import DashBoardMeeting from "../components/DashboardMeetings/index"
function DashboardMeeting() {
  return (
    <div>
      <DashBoardMeeting></DashBoardMeeting>
    </div>
  )
}

export default DashboardMeeting
