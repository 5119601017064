import React from "react";
import LoginHome from "../components/login/index";

function Login() {
  return (
    <>
      <LoginHome />
    </>
  );
}

export default Login;
