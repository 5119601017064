import React from 'react'
import DashBoardCma from "../components/DashboardCma/index"


function DashboardCrm() {
  return (
    <div>
      <DashBoardCma></DashBoardCma>
    </div>
  )
}

export default DashboardCrm
