import React from "react";
import DashBoardHome from "../components/DashboardHome/index";

function Dashboard() {
  return (
    <div>
      <DashBoardHome />
    </div>
  );
}

export default Dashboard;
