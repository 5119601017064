import React from 'react'
import CrmCallscheduled from '../components/DashboardCrm/CrmCallScheduled'
function CrmCallScheduled() {
  return (
    <div>
      <CrmCallscheduled></CrmCallscheduled>
    </div>
  )
}

export default CrmCallScheduled
