import React from 'react'
import Crmlisted from '../components/DashboardCrm/CrmListed'

function CrmListed() {
  return (
    <div>
      <Crmlisted></Crmlisted>
    </div>
  )
}

export default CrmListed
