import React from "react";
import Crmoverview from "../components/DashboardCrm/CrmOverview";
function CrmOverview() {
  return (
    <div>
      <Crmoverview></Crmoverview>
    </div>
  );
}

export default CrmOverview;
