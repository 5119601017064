import React from "react";
import CrmEnquiryReceived from "../components/DashboardCrm/CrmEnquiryReceived/index";
function CrmEnquiryreceived() {
  return (
    <div>
      <CrmEnquiryReceived></CrmEnquiryReceived>
    </div>
  );
}

export default CrmEnquiryreceived;
