import React from 'react'
import DashBoardCrm from "../components/DashboardCrm/index"


function DashboardCrm() {
  return (
    <div>
      <DashBoardCrm></DashBoardCrm>
    </div>
  )
}

export default DashboardCrm
