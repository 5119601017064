import React from "react";
import Crmarchived from "../components/DashboardCrm/CrmArchived";
function CrmArchived() {
  return (
    <div>
      <Crmarchived />
    </div>
  );
}

export default CrmArchived;
