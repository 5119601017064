import React from 'react'
import Crmnegotiation from '../components/DashboardCrm/CrmNegotiation'

function CrmNegotiation() {
  return (
    <div>
      <Crmnegotiation></Crmnegotiation>
    </div>
  )
}

export default CrmNegotiation
