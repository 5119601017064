import React from 'react'
import DashBoardAnalytics from "../components/DashboardAnalytic/index"

function DashboardAnalytics() {
  return (
    <div>
      <DashBoardAnalytics></DashBoardAnalytics>
    </div>
  )
}

export default DashboardAnalytics
