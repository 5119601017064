import React from 'react'
import Crmsold from '../components/DashboardCrm/CrmSold'
function CrmSold() {
  return (
    <div>
      <Crmsold></Crmsold>
    </div>
  )
}

export default CrmSold
