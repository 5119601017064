import React from 'react'
import PropertyForm from "../components/propertyForm/index"

function propertyForm() {
  return (
    <div>
      <PropertyForm></PropertyForm>
    </div>
  )
}

export default propertyForm
