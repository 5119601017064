import React from 'react'
import CrmMeetingscheduled from '../components/DashboardCrm/CrmMettingScheduled'
function CrmMeetingScheduled() {
  return (
    <div>
      <CrmMeetingscheduled></CrmMeetingscheduled>
    </div>
  )
}

export default CrmMeetingScheduled
