import React from 'react'
import DashBoardListing from "../components/DashBoardListing/index";

function DashboardListing() {
  return (
    <div>
      <DashBoardListing></DashBoardListing>
    </div>
  )
}

export default DashboardListing
